import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IconButton, IDropdownOption, IIconProps, MessageBarType, Modal, PrimaryButton, Stack, TooltipHost } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Reducer } from "redux";
import { useId } from "@fluentui/react-hooks";
import { useMediaQuery } from '@mui/material';
import DisplayMessage from "../../../Common/Components/DisplayMessage";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { GenericMessages } from "../../../Helpers/ProjectConstants";
import { getFormattedDate, truncateText } from "../../../Helpers/ProjectHelper";
import { IProjectProps } from "../../../Models/IProjectProps";
import { clearMessageState, requestAddProjectToUserHistory, requestProjectDetails, requestProjectStatusUpdate, setIsEditingSuccess, setProjectContext, updateProjectProps } from "../../../Shared/Actions/Project.action";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import Attachements from "../Components/Attachments";
import TeamMembers from "../Components/TeamMembers";
import SearchProject from "../SearchProjects/SearchProject";
import { container, goBack, headerStyle, headerStatusStyle, stackStyle, titleStyle, width15, width65, linkBookMarkStyle, viewProjectWidth, descriptionStyle, closingDateStyle, sectionheaderStyle, iconStyle, dropdownStyles, readMore, editiconStyle, spinnerCss, renderClosediconStyles, renderOpeniconStyles, dropdownOpenText, dropdownClosedText, contentStyles, descriptionButtonParentStackStyles, primaryDescriptionButtonStyles, descriptionTextStyle } from "../Styles/ViewProject";
import { IProjectContext } from "../../../Models/IProjectContext";
import { useLocation } from 'react-router-dom';
import { app, pages } from "@microsoft/teams-js";
import { ITabNavigation } from "../../../Models/TabNavigation/ITabNavigation";
import View from "../History/View";
import { setNewUserPrompt } from "../../../Shared/Actions/Chat.action";
import ChatHome from "../LitigationChat/ChatHome";
import './viewProject.styles.css';
import { useCreateNavContext } from "./CreateProject/CreateNavContext/CreateNavContext";
import { insightsTitleStyle, projectInsightsWidth } from "../SearchProjects/Styles/SearchProject";
import GenerateInsight from "../GenerateInsights/GenerateInsight";
export const clipBoardIconStyle: React.CSSProperties = {

    mixBlendMode: "normal",
    fontSize: "18px",
    cursor: "pointer",
    marginLeft: 5
};
const ViewProject = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    let history = useHistory();
    const { mainSidePanel, subMenuSidePanel } = useCreateNavContext();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectFiles,
        projectProps,
        isProjectDetailsLoading,
        isProjectDetailsLoadingError,
        projectGetDetailsError,
        projectContext,
        isProjectStatusUpdating,
        isProjectStatusUpdatingError,
        projectStatusUpdateError,
        projectStatusUpdatingSuccess,
        newStatus,
        loginUserpersonaProps,
        isProjectGettingEdited,
        projectEditSuccess,
        projectFileTags,
        isLoadedInBrowser
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    // const navigate = useNavigate();
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [workArea, setWorkarea] = React.useState("");
    const [caseIds, setCaseIds] = React.useState("");
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [hideDialog, setHideDialog] = React.useState(true);
    const [hideStatusDialog, setStatusHideDialog] = React.useState(true);
    const [projectNumber, setProjectNumber] = React.useState<string>('');
    const [tabNavigation, setTabNavigation] = React.useState<ITabNavigation>({});
    const [isNavigationFromDashboard, setIsNavigationFromDashboard] = React.useState(false);
    const [backToProjectList, setBackToProjectList] = React.useState(false);
    const [generateInsightsClicked, setGenerateInsights] = React.useState(false);
    //&backToProjectList=true
    // Description Popup Start
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = React.useState<boolean>(false);


    const showDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };
    const hideDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };
    const titleId = useId("title");
    // Description Popup End

    //Status Start
    const dialogStatusContentProps = {
        type: DialogType.largeHeader,
        title: "Change status",
    };
    const toggleStatusHideDialog = () => setStatusHideDialog(value => !value);


    const dropDownOptions: IDropdownOption[] = [
        { key: "Open", text: 'Open', title: 'Open', data: { icon: 'SkypeCircleCheck' } },
        { key: "Closed", text: 'Closed', title: 'Closed', data: { icon: 'StatusCircleBlock2' } },
    ];
    const onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <div>
                {option.data && option.data.icon && option.text === "Open" && (
                    <Icon style={renderOpeniconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                {option.data && option.data.icon && option.text === "Closed" && (
                    <Icon style={renderClosediconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                {option.text === "Open" && (<span style={dropdownOpenText}>{option.text}</span>)}
                {option.text === "Closed" && (<span style={dropdownClosedText}>{option.text}</span>)}
            </div>
        );
    };

    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        const option = options[0];
        return (
            <div>
                {option.data && option.data.icon && option.text === "Open" && (
                    <Icon style={renderOpeniconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                {option.data && option.data.icon && option.text === "Closed" && (
                    <Icon style={renderClosediconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                {option.text === "Open" && (<span style={dropdownOpenText}>{option.text}</span>)}
                {option.text === "Closed" && (<span style={dropdownClosedText}>{option.text}</span>)}
            </div>
        );
    };
    const [selectedStatus, setSelectedStatusItem] = React.useState<IDropdownOption>();

    const onStatusChange = (): void => {
        var newstatus = selectedStatus.text.toLowerCase() === "closed" ? "Open" : "Closed";
        dispatch(requestProjectStatusUpdate(projectContext, projectProps, newstatus));
        toggleStatusHideDialog();
    }
    //Status End

    React.useEffect(() => {
        app.getContext().then((context) => {
            if (context?.page?.subPageId !== '') {
                setTabNavigation(JSON.parse(context.page.subPageId));
            }
        });
        const projectNumber: string = queryParams.get('projectNumber')
        const isBackToProjectList: string = queryParams.get('backToProjectList')
        setProjectNumber(projectNumber);
        setBackToProjectList(isBackToProjectList === 'true' ? true : false);
        dispatch(requestAddProjectToUserHistory(projectNumber, loginUserpersonaProps?.secondaryText));
    }, []);

    React.useEffect(() => {
        if (Object.keys(tabNavigation).length > 0) {
            setIsNavigationFromDashboard(tabNavigation?.isFromDashboard)
            setProjectNumber(tabNavigation?.projectNumber)
        }
    }, [tabNavigation]);

    React.useEffect(() => {
        if (projectNumber?.length > 0) {
            const prjInContext: IProjectContext = {
                number: projectNumber
            }
            dispatch(setProjectContext(prjInContext));
            dispatch(requestProjectDetails(prjInContext, loginUserpersonaProps?.secondaryText));
        }
    }, [projectNumber, loginUserpersonaProps]);

    React.useEffect(() => {
        if (generateInsightsClicked && projectProps && projectProps.workArea.includes("Litigation")) {
            dispatch(setNewUserPrompt("Go through all documentation and use the following format to identify and append information that you find. Make sure that you are not generating any information that is not found in the scope of your analysis.Document Identifier: Case Number: Document Date: Received Date: Involved Parties: Plaintiff(s): Defendant(s) (Our Entity): Other Relevant Parties: Jurisdiction: Court: Location (State/Country): Summary of Allegations: What are the primary allegations or claims made in the document? Are there specific damages or remedies being sought? Facts Presented: What key facts are outlined in the notice that are pertinent to the allegations? Claims and Demands: Detail the specific claims and any compensation or actions demanded by the plaintiff. Legal Basis: What laws, statutes, or legal precedents are cited in the notice? What are the main legal theories or arguments put forth by the opposing party? Potential Implications and Risks: What are the potential financial implications (estimated damages, costs of defense)? Are there any noted reputational risks to our entity? What operational impacts might this case have? Next Steps and Deadlines: What are the critical deadlines mentioned in the document (e.g., response dates, hearing dates)? What initial steps are suggested for our legal team? Litigation Hold and Document Preservation Needs: What types of documents and electronic information need to be preserved? What instructions are provided for implementing a litigation hold? How should compliance with the litigation hold be monitored and maintained? Additional Notes: Any other notable comments or unusual aspects noted in the document?"));
        }
    }, [generateInsightsClicked, projectProps.workArea]);

    React.useEffect(() => {
        const id = setTimeout(function () {
            if (projectEditSuccess) {
                dispatch(setIsEditingSuccess(false))
            }
        }, 4000);
        return () => clearInterval(id);
    }, [projectEditSuccess])

    function toggleCopilot(toggle: boolean){
        setShowLitigationChatModal(toggle);
    }

    function generateInsightsButtonClicked(toggle: boolean){
        setGenerateInsights(toggle);
    }

    React.useEffect(() => {
        setTitle(projectProps.title);
        setDescription(projectProps.description);
        setWorkarea(projectProps.workArea);
        setCaseIds(projectProps?.caseIds?.join(", "));
        (projectProps.startDate != null && projectProps?.startDate?.length > 0) ? setStartDate(projectProps.startDate) : setStartDate("");
        (projectProps.endDate != null && projectProps?.endDate?.length > 0) ? setEndDate(projectProps.endDate) : setEndDate("");
        if (projectProps?.status?.toLowerCase() === "closed") { setSelectedStatusItem(dropDownOptions[1]); }
        else { setSelectedStatusItem(dropDownOptions[0]); }
    }, [projectProps]);

    
    React.useEffect(() => {
        const tempProjectProps: IProjectProps = projectProps;
        if (projectStatusUpdatingSuccess) {
            tempProjectProps.status = newStatus;
            dispatch(updateProjectProps(tempProjectProps));
            if (newStatus.toLowerCase() === "closed") { setSelectedStatusItem(dropDownOptions[1]); }
            else { setSelectedStatusItem(dropDownOptions[0]); }
        }
    }, [projectStatusUpdatingSuccess]);

    function redirectToForm(): void {
        history.push(`/createproject?projectNumber=${projectNumber}&backToProjectList=true`);
        dispatch(clearMessageState());
    }

    const goBackRedirect = () => {
        history.push("/projects");
    }
    const opeSPOUrl = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const [showLitigationChatModal, setShowLitigationChatModal] = React.useState(false);
    const handleOpenLitigationChatModal = () => {
        dispatch(setNewUserPrompt(''))
        setShowLitigationChatModal(p => !p)
        setGenerateInsights(false);
    }
    return (
        <Stack style={container}>
            {isProjectDetailsLoading && (
                <>
                    <span role="alert" aria-label={GenericMessages.projectsLoading} aria-live="assertive" />
                    <div style={spinnerCss}>
                        <DisplaySpinner
                            accessabilityMessage={GenericMessages.projectsLoading}
                            spinnerText={GenericMessages.projectsLoading}
                            spinnerPosition={"right"}
                        />
                    </div>
                </>
            )}
            {!isProjectGettingEdited && projectEditSuccess && (
                <>
                    <DisplayMessage
                        accessabilityMessage={projectProps.title + " has been edited successfully"}
                        messageType={MessageBarType.success}
                        message={projectProps.title + " has been edited successfully"}
                    />
                </>
            )}
            {isProjectStatusUpdating && (
                <>
                    <span role="alert" aria-label={GenericMessages.updatingProjectStatus} aria-live="assertive" />
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.updatingProjectStatus}
                        spinnerText={GenericMessages.updatingProjectStatus}
                        spinnerPosition={"right"}
                    />
                </>
            )}
            {!isProjectStatusUpdating && projectStatusUpdatingSuccess && !isProjectDetailsLoading && (
                <>
                    <DisplayMessage
                        accessabilityMessage={projectProps.title + " status has been changed successfully"}
                        messageType={MessageBarType.success}
                        message={projectProps.title + " status has been changed successfully"}
                    />
                </>
            )}
            {!isProjectDetailsLoading && isProjectDetailsLoadingError && (
                <>
                    <ErrorDisplay source={projectGetDetailsError?.errorMessage} />
                    {/* <Stack horizontal style={goBack} onClick={goBackRedirect}>
                        <Stack>
                            <Icon
                                iconName={"ChevronLeft"}
                                tabIndex={0}
                                style={iconStyle}
                            />
                        </Stack>
                        <Stack> Go Back </Stack>
                    </Stack> */}
                </>
            )}
            {!isProjectStatusUpdating && isProjectStatusUpdatingError && (
                <>
                    <ErrorDisplay source={projectStatusUpdateError?.errorMessage} />
                </>
            )}
            {!isProjectDetailsLoading && !isProjectDetailsLoadingError && !isProjectStatusUpdating && (
                <>

                    {/*Project Details */}
                    <Stack horizontal style={stackStyle}>
                        <Stack horizontal style={{
                                ...viewProjectWidth,
                                width: showLitigationChatModal && !mainSidePanel && !subMenuSidePanel ? '60%' : showLitigationChatModal && mainSidePanel && !subMenuSidePanel ? '80%' : showLitigationChatModal && !mainSidePanel && subMenuSidePanel ? '80%' :
                                    showLitigationChatModal && mainSidePanel && subMenuSidePanel ? '70%' :
                                        '70%', // Default width if showLitigationChatModal is false
                                overflowX: 'auto'
                            }}>
                            <Stack>
                                {/* Header */}
                                <Stack >
                                    <Stack horizontal style={{ minWidth: 600 }}>
                                            <div style={titleStyle}>

                                                <TooltipHost content={title}>
                                                    {truncateText(title, 35, 35)}
                                                </TooltipHost>
                                                <Icon
                                                    iconName={"Edit"}
                                                    tabIndex={0}
                                                    style={editiconStyle}
                                                    onClick={(e) => redirectToForm()}
                                                />
                                            </div>
                                        <Stack style={linkBookMarkStyle} horizontal >
                                            <View item={projectProps} />
                                            {projectProps?.spoUrl && (
                                                <TooltipHost content={'Open SPO url associated to this project'}>
                                                    <Icon tabIndex={0}
                                                        onClick={() => opeSPOUrl(projectProps?.spoUrl)}
                                                        data-testid="copy-icon"
                                                        iconName="Link"
                                                        style={clipBoardIconStyle}
                                                        aria-label="click on this icon to copy the spo url to clipboard"
                                                    /></TooltipHost>)}
                                            {startDate.length > 0 && (
                                                <span style={{ marginLeft: 5 }}>
                                                    {getFormattedDate(startDate)}
                                                </span>
                                            )}
                                        </Stack>
                                        <Stack style={width15}>
                                            <div style={headerStatusStyle}>
                                                <Dropdown
                                                    selectedKey={selectedStatus ? selectedStatus.key : undefined}
                                                    onChange={toggleStatusHideDialog}
                                                    onRenderOption={onRenderOption}
                                                    onRenderTitle={onRenderTitle}
                                                    options={dropDownOptions}
                                                    styles={dropdownStyles}
                                                    disabled={projectContext.isBillable}
                                                />
                                                <Dialog
                                                    hidden={hideStatusDialog}
                                                    onDismiss={toggleStatusHideDialog}
                                                    dialogContentProps={dialogStatusContentProps}
                                                >
                                                    Are you sure you want to change the status of the project?
                                                    <DialogFooter>
                                                        <PrimaryButton onClick={onStatusChange} text="Confirm" />
                                                        <DefaultButton onClick={toggleStatusHideDialog} text="Close" />
                                                    </DialogFooter>
                                                </Dialog>
                                            </div>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                {/* Description */}
                                <Stack>
                                    <div style={descriptionStyle}>
                                        <span style={descriptionTextStyle}>
                                            {truncateText(description, 500, 500)}
                                        </span>
                                        {description?.length > 500 && (
                                            <span onClick={showDescriptionModal} style={readMore}>Read More
                                                <Modal
                                                    titleAriaId={titleId}
                                                    isOpen={isDescriptionModalOpen}
                                                    onDismiss={hideDescriptionModal}
                                                    isBlocking={true}
                                                    containerClassName={contentStyles.container}
                                                >
                                                    <div className={contentStyles.header}>
                                                        <h2 className={contentStyles.heading} id={titleId} tabIndex={0}>
                                                            {title}
                                                        </h2>
                                                    </div>
                                                    <div className={contentStyles.body}>
                                                        <Stack>
                                                            <Stack>
                                                                <p className={contentStyles.bodyContent} tabIndex={0}>
                                                                    {description}
                                                                </p>
                                                            </Stack>
                                                            <Stack styles={descriptionButtonParentStackStyles}>
                                                                <DefaultButton onClick={hideDescriptionModal} styles={primaryDescriptionButtonStyles}>
                                                                    Close
                                                                </DefaultButton>
                                                            </Stack>
                                                        </Stack>
                                                    </div>

                                                </Modal>
                                            </span>
                                        )}
                                    </div>
                                </Stack>
                                {/* Work area */}
                                <div style={{ minWidth: 600 }}>
                                    {workArea?.length > 0 && (
                                        <>
                                            <Stack>
                                                <div style={sectionheaderStyle}>
                                                    Work Area
                                                </div>
                                                <div style={closingDateStyle}>
                                                    {workArea}
                                                </div>
                                            </Stack>
                                        </>
                                    )}</div>
                                <div style={{ minWidth: 600 }}>
                                    {caseIds && caseIds?.length > 0 && (
                                        <>
                                            <Stack>
                                                <div style={sectionheaderStyle}>
                                                    Litigation Cases
                                                </div>
                                                <div style={closingDateStyle}>
                                                    {caseIds}
                                                </div>
                                            </Stack>
                                        </>
                                    )}</div>
                                <div style={{ minWidth: 600 }}>
                                    {/* Closing Date */}
                                    {endDate.length > 0 && (
                                        <>
                                            <Stack>
                                                <div style={sectionheaderStyle}>
                                                    Closing Date
                                                </div>
                                                <div style={closingDateStyle}>
                                                    {getFormattedDate(endDate)}
                                                </div>
                                            </Stack>
                                        </>
                                    )}</div>


                                <div style={{ minWidth: 600 }}>
                                    {/* Attachments */}
                                    <Attachements></Attachements>
                                </div>
                                <div style={{ minWidth: 600 }}>
                                    {/* Team Members */}
                                    <TeamMembers></TeamMembers>
                                </div>
                            </Stack>
                            <Stack style={projectInsightsWidth}>
                            <Stack style={insightsTitleStyle}>
                                {projectProps && projectProps.workArea && projectProps.workArea.includes("Litigation") && (
                                    <GenerateInsight toggleCopilot={toggleCopilot} generateInsightsButtonClicked={generateInsightsButtonClicked} />
                                )}
                                Project Insights
                                <SearchProject projectNumber={projectNumber} />
                            </Stack>
                            </Stack>
                        </Stack>

                        {projectContext && projectContext?.number && (

                            <div className='copilot-container' style={{top: isLoadedInBrowser ? '100px' : '50px', position: 'fixed', right: '10px', zIndex: 1000, pointerEvents: 'none'}}>
                            <Stack>
                                <div style={{ marginLeft: 'auto', pointerEvents: 'auto' }}>
                                    {/* Copilot Icon */}
                                    {!showLitigationChatModal && (
                                        <img
                                            src="https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg"
                                            alt="CoPilot Icon"
                                            onClick={handleOpenLitigationChatModal}
                                            style={{
                                                cursor: 'pointer',
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    )}
                                </div>
                                {/* Chat Window */}
                                {showLitigationChatModal && (
                                    <Stack className="copilot-chat-window" style={{ position: 'fixed', top: '100px', right: '10px', zIndex: 1000, backgroundColor: 'rgba(245, 245, 245, 0.9)', backdropFilter: 'blur(10px)', borderRadius: '5px', padding: '10px', pointerEvents: 'auto', width: '425px', maxWidth: '90vw', overflow: 'auto', wordWrap: 'break-word' }}>
                                        <Stack horizontal>
                                            <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { marginLeft: 10 } }}>
                                                <img
                                                    src="https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg"
                                                    alt="CoPilot Icon"
                                                    onClick={handleOpenLitigationChatModal}
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: 24,
                                                        height: 24,
                                                    }}
                                                />
                                                <Stack className="copilot-chat-label">Legal Ease AI Assistant</Stack>
                                            </Stack>

                                            <Stack className="copilot-chat-close"><span tabIndex={0} style={{ cursor: 'pointer' }} onClick={handleOpenLitigationChatModal}>X</span></Stack>
                                        </Stack>
                                        <div>
                                            <ChatHome user={loginUserpersonaProps} projectNumber={projectContext?.number}/>
                                        </div>
                                    </Stack>
                                )}
                            </Stack>
                            </div>
                        )}
                    </Stack>
                </>
            )}
        </Stack>
    )
}

export default ViewProject;